::-moz-selection {
    background: #00bfbf;
    color: #fafafa;
    text-shadow: none;
}
::selection {
    background: #00bfbf;
    color: #fafafa;
    text-shadow: none;
}
::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
#root {
  position: relative;
}
html {
    overflow: scroll;
    overflow-x: hidden;
    font-size: 16px;
 
}

body {
  overflow-x: hidden;

  width: 100vw;
    transition: all 0.5s ease;
}
p {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}
.img-fluid {
  border-radius: 60%;
  width: 200px;
  height: 200px;
}
.sticky {
    /* background-color: #1b1a2ea9 !important; */
    transition: all 0.3s ease-out 0s !important;
    /* box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important; */
    backdrop-filter: blur(15px) !important;
  }
  
  .navbar {
    position: fixed !important;
    transition: all 0.3s ease-out 0s !important;
    padding: 0.3rem 2rem !important;
    font-size: 1.2rem !important;
  }
  
  .navbar-toggler {
    position: relative !important;
    background-color: transparent !important;
    border-color: transparent !important;
    color: transparent !important;
  }
  
  .navbar-toggler span {
    display: block !important;
    /* background-color: #fff !important; */
    height: 4px !important;
    width: 27px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    transform: rotate(0deg) !important;
    left: 0 !important;
    opacity: 1 !important;
  }
  
  .navbar-toggler:focus,
  .navbar-toggler:active {
    outline: 0 !important;
  }
  
  .navbar-toggler span:nth-child(1),
  .navbar-toggler span:nth-child(3) {
    transition: transform 0.35s ease-in-out !important;
    transition: transform 0.35s ease-in-out !important;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(135deg) !important;
    opacity: 0.9 !important;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px !important;
    visibility: hidden !important;
    background-color: transparent !important;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(-135deg) !important;
    opacity: 0.9 !important;
  }
  
  @media (max-width: 767px) {
    .navbar {
      padding: 1rem 2rem !important;
      font-size: 1.4rem !important;
      /* background-color: #181a27 !important; */
    }
    .navbar-nav .nav-item a::after {
      display: none !important;
    }
  }
  .navbar-brand {
    color: rgb(250, 250, 250) !important;
  }
  
  .logo {
    height: 1.4em !important;
    width: 2.5em !important;
  }
  
  .navbar-nav .nav-link {

    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  
  .nav-link {
    padding: 0.8rem 1rem !important;
  }
  
  @media (max-width: 767px) {
    .nav-link {
      padding: 0.7rem 1rem !important;
    }
  }
  .main {
    width: 100vw !important;
    z-index: 100;
  }
  .navbar-nav .nav-item {
    position: relative;
    margin-left: 40px;
    margin-top: 20px;
    cursor: pointer;
  }
  
  .navbar-nav .nav-item a {
    font-weight: 400;
    transition: all 0.3s ease-out 0s;
    position: relative;
    z-index: 1;
  }
  
  .navbar-nav .nav-item a::after {
    content: "";
    position: relative;
    display: block;
    height: 4px;
    width: 0;
    border-radius: 16px;
    background: #00E5FF;
    /* margin-top: -17px; */
    top: 0;
    left: 0;
    z-index: 20;
    transition: all 0.2s ease-out 0s;
  }
  
  .navbar-nav .nav-item a:hover::after {
    width: 100%;
  }
  .project-heading {
      padding-top: 100px;
  }
 .mouse-tip {
   width: 20px;
   height: 40px;
   border: 1px solid #fff;
   border-radius: 20%;
 }
 .scrollIndicator {
  border: 2px solid var(--color);
  border-radius: 20px;
  width: 26px;
  height: 38px;
  position: fixed;
  bottom: 12%;
  left: 47%;
  opacity: 0.5;
  transition-property: opacity;
  transition-duration: var(--durationL);
  transition-timing-function: ease;
  z-index:2000

}

.scrollIndicator:before {
  content: '';
  height: 7px;
  width: 3px;
  background: var(--color);
  border-radius: 4px;
  position: absolute;
  top: 6px;
  left: 46%;
  transform: translateX(-1px);
  animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
}
@keyframes scale {
  0% {
    transform: translateY(1px);
  }

  50% {
    transform: translateY(8px);
  }

  78%
  {
    transform: translateY(1px);
  }
  100% {
      opacity: 0
  }
}

  .project-card {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    height: auto !important;
  }

  .project-card-view {
    background-color: transparent !important;
    opacity: 0.9 !important;
    transition: all 0.5s ease 0s !important;
    height: 100% !important;
  }

  .project-card-view:hover {
    
    background-color: transparent !important;
    box-shadow: inset 6px 6px 30px rgba(246,246,246,.2);
    transition: all .3s ease;
    transform: scale(1.1) !important;
    overflow: hidden !important;
  }





  .home-about-section {
    width: 100%;
    position: relative;
    margin: 200px 0;
    padding-bottom: 70px !important;
    padding-top: 70px !important;
  }
  
  .home-about-description {
    color: white !important;
    padding-top: 100px !important;
    padding-bottom: 20px !important;
    text-align: center;
   
  }
  .my-avatar {
    text-align: center !important;
  }
  .home-about-body {
    padding-top: 50px;
    font-size: 1.2em !important;
    text-align: left;
  }
  
  .home-about-social {
    text-align: center !important;
    padding-top: 25px;
    color: white !important;
  }
  
  .home-about-social-links {
    justify-content: center !important;
    padding-top: 15px !important;
    display: inline-block !important;
    position: relative !important;
    padding-inline-start: 0 !important;
  }
  
  .home-social-icons {
    position: relative !important;
    display: inline-block !important;
    width: 40px !important;
    height: 40px !important;
    text-align: center !important;
    font-size: 1.2em !important;
    line-height: 2em !important;
    background: rgba(255, 255, 255, 0.972) !important;
    border-radius: 50% !important;
    transition: 0.5s !important;
  }

  .resume-section {
    position: relative !important;
    padding-top: 110px !important;
    padding-bottom: 30px !important;
    background-image: var(--section-background-color) !important;
    color: white !important;
  }
  
  .resume {
    padding-top: 50px;
    padding-bottom: 50px;
    justify-content: center;
  }
  
  .resume-left {
    padding-right: 80px !important;
  }
  
  .resume-right {
    padding-left: 80px !important;
  }
  
  @media (max-width: 767px) {
    .resume-left {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
  
    .resume-right {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
  }
  .resume .resume-title {
    font-size: 2em;
    font-weight: 700;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  
  .resume .resume-item {
    padding: 0 0 10px 25px;
    margin-top: -2px;
    border-left: 2px solid #8a49a8;
    position: relative;
  }
  
  .resume .resume-item .resume-title {
    line-height: 18px;
    font-size: 0.9em;
    background: #5234795d;
    padding: 8px 15px;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .resume .resume-item ul {
    padding-left: 20px;
    /* text-align: justify; */
  }
  
  .resume .resume-item ul li {
    padding-bottom: 10px;
    list-style: none;
  }
  
  .resume .resume-item:last-child {
    padding-bottom: 0;
  }
  
  .resume .resume-item::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    left: -9px;
    top: 0;
    background: #fff;
    border: 2px solid #8a49a8;
  }